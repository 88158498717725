import { Button } from '@mui/material'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Config } from '../config'
import useFirebase from '../hooks/useFirebase'
import { AskForConstants, EstimateStatus, Invoice, InvoiceStatus } from '../types/invoiceType'
import DisplayInvoice from './displayInvoice'
import Header from './header'
import '../App.css'

/* Allows a user to make payment on an invoice. On this page we also prompt the user for reviews and for referrals, depending on which value is set in the database for what to ask the customer for. */
const Estimate = () => {

    const { estimateId } = useParams()

    const [invoice, setInvoice] = React.useState<Invoice | null>(null)

    const { db, analytics } = useFirebase()

    const [estimateAccepted, setEstimateAccepted] = React.useState(false)

    useEffect(() => {
        if (invoice?.status == "paid") {
            window.location.href = "/success/" + estimateId
        }

        if (invoice?.status === EstimateStatus.accepted) {
            setEstimateAccepted(true)
        }

    }, [invoice])

    const acceptEstimate = () => {
        if (!invoice) { return }

        const docRef = doc(db, "invoices", invoice.id);
        updateDoc(docRef, {
            status: EstimateStatus.accepted
        }).then(() => {
            setInvoice({ ...invoice, status: EstimateStatus.accepted })
            console.log("Document successfully updated!");
        })

        fetch(`${Config.Env.MessagesUrl}/sendCustomMessage`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                phoneNumber: '7023722579',
                message: `${invoice.fullName} Accepted your Estimate!`,
                fromNumber: "7028834839", // This is completely irrelevant, but it's required by the API, so I'm just using a random number, but it's not used. Needs to be fixed.
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
            })
            .catch((error) => {
                console.error("Error:", error);
            })
    }

    return (
        <div style={{ maxWidth: "800px" }} className="mx-3">
            {
                <Header />
            }
            {
                <Container className="my-4">
                    <Row>
                        <Col>
                            <h1>Defined Window Cleaning has sent you an {invoice?.isEstimate ? "Estimate" : "Invoice"}</h1>
                        </Col>
                    </Row>
                    {
                        invoice?.status === EstimateStatus.accepted &&
                        <Row>
                            <Col>
                                <h5 style={{ color: "green" }}>Estimate Accepted</h5>
                            </Col>
                        </Row>
                    }
                </Container>
            }
            {
                invoice?.isEstimate === true && invoice?.status === EstimateStatus.open &&
                <Button variant="contained" color="primary" fullWidth={true} onClick={acceptEstimate} >Accept Estimate</Button>
            }
            {
                invoice?.isEstimate === true && invoice?.status === EstimateStatus.accepted && invoice.depositLink !== undefined &&
                <Button variant="contained" color="success" fullWidth={true} href={invoice.depositLink} >Tap to Pay Deposit of ${ invoice.depositAmount }</Button>
            }
            {estimateId &&
                <div className="my-3">
                    <DisplayInvoice invoiceId={estimateId} setInvoice={setInvoice} />
                </div>
            }

        </div >
    )
}

export default Estimate