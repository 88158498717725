import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'

const Header = () => {

    return (
        <Container className="mt-3">
            <Row>
                <Col xl="1" md="2" xs="3">
                    <Image fluid src="/logo-notext.png"></Image>
                </Col>
                <Col xs="9" md="10" xl="11">
                    <Container>
                        <Row>
                            <Col className="mb-2" xs={12}><strong>Defined Window Cleaning</strong></Col>
                            <div style={{ fontSize: 16 }}>
                                <Col xs={12}>info@definedcleaning.com</Col>
                                <Col xs={12}>definedcleaning.com</Col>
                                <Col xs={12}>(702) 747-0901</Col>
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Header