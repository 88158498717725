import { green } from '@mui/material/colors'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { firebaseConfig } from '../firebaseConfig'
import { Invoice, InvoiceStatus } from '../types/invoiceType'

const DisplayInvoice = (props: { invoiceId: string, setInvoice: any }) => {

    const [invoice, setInvoice] = React.useState<Invoice | null>(null)

    const invoiceId = props.invoiceId

    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app)

    const analytics = getAnalytics(app);

    useEffect(() => {
        const getInvoice = async () => {

            const docRef = doc(db, "invoices", invoiceId);
            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) {
                console.log("No such document!");
                logEvent(analytics, 'invoice_not_found', {
                    invoiceId: invoiceId
                })
                return
            }

            const invoice: Invoice = docSnap.data() as Invoice

            if (invoice) {
                setInvoice(invoice)
                props.setInvoice(invoice)
            } else {
                console.log("Invoice is in the incorrect format!");
                logEvent(analytics, 'invoice_wrong_format', docSnap.data())
            }
        }

        getInvoice()
    }, [])

    return (
        <Container className="mb-5">
            {
                invoice &&
                <div className="p-4" style={{ borderWidth: 0.5, borderStyle: "solid" }}>
                    <Row>
                        <Col className="mt-3"><h3>{invoice.isEstimate === false ? "Invoice" : "Estimate"}</h3> {invoice.status === InvoiceStatus.paid && <h3 style={{ color: green[300] }}>Paid</h3>} </Col>
                    </Row>
                    <hr />

                    <Row>
                        <Col xs="12" className="mb-3">
                            <strong>{invoice.title}</strong>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" className="mt-3">
                            <strong>Customer Information</strong>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12}>
                            {invoice.fullName}
                        </Col>
                        <Col xs={12}>
                            {invoice.address}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mt-3">
                            <strong>Message from Defined Window Cleaning</strong>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs="12" >
                            {invoice.message}
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs="12" >
                            <strong>Work List Items</strong>
                        </Col>
                    </Row>


                    {
                        invoice.lineItems === undefined && invoice.servicesDone.map((service, index) => {
                            return (
                                <Row>
                                    <Col xs="12" key={index}>
                                        <strong>{index + 1}.</strong> {service}
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    {
                        invoice.lineItems !== undefined && invoice.lineItems.map(((item: any, index: number) => {
                            return (
                                <Row>
                                    <Col xs="12">
                                        <hr></hr>
                                    </Col>
                                    <Col xs="8" className="mb-1">
                                        {index + 1}. <strong>{item.name}</strong>
                                    </Col>
                                    <Col xs="4" className="d-flex justify-content-end">
                                        <strong>${Math.ceil(item.unitPrice)}</strong>
                                    </Col>
                                    <Col xs="12">
                                        <p style={{ fontSize: "12px" }}>{item.description}</p>
                                    </Col>
                                </Row>
                            )
                        }))
                    }
                    <hr />
                    <Row>
                        <Col xs="8" className="mb-3">
                            Total Price:
                        </Col>
                        <Col xs="4" className="mb-3 d-flex justify-content-end">
                            <strong>${invoice.amount}</strong>
                        </Col>
                    </Row>
                </div>
            }
            {
                !invoice &&
                <Row>
                    <Col xs="12" className="my-3">
                        <div className="alert alert-warning"><h3>Loading invoice...</h3></div>
                    </Col>
                </Row>
            }
        </Container>
    )

}

export default DisplayInvoice