import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import validator from 'validator';
import { Config } from '../config';

const CheckoutForm = (props: { email: string }) => {

  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState<string>();

  const [processing, setProcessing] = useState<boolean>(false);

  const returnUrl = Config.Env.PaymentSuccessfulReturnUrl

  const { invoiceId } = useParams()

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Check to make sure that the user has entered a valid email address
    if (validator.isEmail(props.email) === false) {
      setErrorMessage("Please enter a valid email address to send the receipt to.")
      return
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.      
      return;
    }

    setProcessing(true)
    setErrorMessage(undefined)

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${returnUrl}/success/${invoiceId}/${props.email}`,
      },
    });

    setProcessing(false)

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentElement className="mb-3" />
      {        
        errorMessage &&
        < div className="alert alert-warning">
          <div>{errorMessage}</div>
        </div>
      }
      {        
        processing ? <Button variant="primary" type="submit" disabled>Processing...</Button> : <Button variant="primary" type="submit">Submit</Button>
      }

    </Form >
  );
};

export default CheckoutForm;