import React, {  useState } from 'react'
import {  Col, Container, Form, Row } from 'react-bootstrap'
import { Button } from '@mui/material'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { addDoc, collection, getFirestore } from "firebase/firestore";
import FeedbackTextArea from './feedback';
import { firebaseConfig } from '../firebaseConfig'

const Review = () => {

    const [rating, setRating] = React.useState("")

    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

    const ratingResponses = {
        Bad: "I’m so sorry you had a bad experience with us. Can you please tell us what happened that you did not like?",
        Good: "Thank you for your feedback. We're glad you enjoyed our services. However, we would love to do great! Please let us know how we can improve.",
        Great: "We're so happy to hear that you felt we did great! Was there anything in particular you really liked? If you have a moment, would you mind leaving us a review on Google?"
    }

    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app)

    const analytics = getAnalytics(app);    

    const googleReviewLink = "https://g.page/r/CTMN70wLFLg-EB0/review"

    const submitFeedback = async (feedback: string) => {

        let docRef = await addDoc(collection(db, "feedback"), {
            rating: rating,
            feedback: feedback
        })

        setFeedbackSubmitted(true)

        logEvent(analytics, 'submit_feedback', {
            docRefId: docRef.id
        })
    }

    /**
     * It takes a string as an argument, and returns a component based on the string
     * @param {string} rating - string - this is the rating that the user selected.
     */
    const handleRating = (rating: string) => {
        switch (rating) {
            case 'Bad':
                return (
                    <FeedbackTextArea key="Hello" header={ratingResponses.Bad} submitFeedback={submitFeedback} />
                )
            case 'Good':
                return <FeedbackTextArea header={ratingResponses.Good} submitFeedback={submitFeedback} />
            case 'Great':
                return (
                    <div>
                        <Row className="mb-3">
                            <Col>
                                {ratingResponses.Great}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="contained" target="_blank" href={googleReviewLink} type="submit">Leave a Google Review</Button>
                            </Col>
                        </Row>
                    </div>
                )
        }
    }

    return (
        <div>
            {
                !feedbackSubmitted &&
                <div>
                    {
                        !rating &&
                        <Container>
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h4>How would you say we did?</h4>
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                {
                                    Object.keys(ratingResponses).map((rating, index) => {
                                        return (
                                            <Col key={index} xs={4}>
                                                <Button variant="contained" style={{ width: "100%", height: "60px", fontSize: "18px" }} onClick={() => setRating(rating)}>{rating}</Button>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Container >
                    }
                    {
                        rating &&
                        <Container>
                            <div>
                                {handleRating(rating)}
                            </div>
                        </Container>
                    }
                </div>
            }
            {
                feedbackSubmitted &&
                <Container>
                    <Row>
                        <Col xs="12">
                            <h5>
                                Thank you so much for your feedback! We will definitely use this information to improve, and do a better job next time!
                            </h5>
                        </Col>
                    </Row>
                </Container>

            }
        </div >
    )
}

export default Review