import React, { useEffect } from 'react'
import { Invoice } from '../types/invoiceType'
import { Button } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { indigo, purple } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import useError from '../hooks/useError';
import useFirebase from '../hooks/useFirebase';
import { addDoc, collection, getDoc, getDocs, query, where } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import AddToCalendar from './addToCalendar';

function isWeekend(date: Dayjs) {
    return date.day() === 0 || date.day() === 6;
}

const SetAppointmentDate = (
    props: { invoice: Invoice }
) => {

    const [date, setDate] = React.useState<Dayjs | null>(null)

    const { setError, Error } = useError()

    const { db, analytics } = useFirebase()

    const [processing, setProcessing] = React.useState<boolean>(false)

    const [loading, setLoading] = React.useState<boolean>(true)

    const [appointmentBooked, setAppointmentBooked] = React.useState<boolean>(false)

    useEffect(() => {
        setDate(getDateFromNow(3))
        const getAppointment = async () => {
            setLoading(true)
            try {
                const docRef = await getDocs(query(collection(db, "appointments"), where("invoiceId", "==", props.invoice.id)))
                if (docRef.docs.length > 0) {
                    setAppointmentBooked(true)
                    const date = dayjs(docRef.docs[0].data().date.toDate())
                    setDate(date)
                }
            } catch (error) {
                console.log(error)
                logEvent(analytics, 'error', {
                    error: error,
                    invoiceId: props.invoice.id,
                    clientId: props.invoice.clientId
                })
            }

            setLoading(false)
        }

        getAppointment()
    }, [])

    const getDateFromNow = (monthsFromNow: number) => {
        return dayjs().add(monthsFromNow, 'month')
    }

    const getMonthsFromNow = (monthsFromNow: number) => {
        return dayjs().add(monthsFromNow, 'month').format('MMMM')
    }

    const getDiscount = () => {
        if (!date) { return 0 }

        if (date.month() < getDateFromNow(4).month()) {
            return 20
        } else if (date.month() < getDateFromNow(7).month()) {
            return 15
        }

        return 5
    }

    const bookDate = async () => {
        if (!date) {
            setError("Please select a date")
            return
        }

        if (isWeekend(date)) {
            setError("Please select a weekday")
            return
        }

        if (date.isBefore(dayjs())) {
            setError("Please select a date in the future")
            return
        }

        setProcessing(true)

        try {
            const docRef = await addDoc(collection(db, "appointments"), {
                clientId: props.invoice.clientId,
                invoiceId: props.invoice.id,
                date: date.toDate(),
                discount: getDiscount()
            })

            setAppointmentBooked(true)
        } catch (error) {
            console.log(error)
            logEvent(analytics, 'error', {
                error: error,
                invoiceId: props.invoice.id,
                clientId: props.invoice.clientId
            })

            setError("There was an error booking your appointment. Please try again later.")
        }

        setProcessing(false)
    }

    return (
        <div>
            {
                !loading &&
                <div>
                    {
                        appointmentBooked && date &&
                        <div className="alert alert-success my-3" style={{ backgroundColor: indigo[300], color: "white" }}>
                            <div className="my-2">
                                <h4>Appointment Booked for {date?.format("MMMM DD YYYY")}!</h4>
                            </div>
                            <div className="my-3">
                                Thanks so much for booking your appointment! We will contact you a week before to confirm the appointment date and time. <strong>You will receive your discount of {getDiscount()}% when you pay for the next window cleaning.</strong>
                            </div>
                            <div className="my-3">
                                <AddToCalendar price={props.invoice.amount - props.invoice.amount * (1 / getDiscount())} date={date.format("YYYY-MM-DD")} />
                            </div>
                        </div>
                    }
                    {
                        !appointmentBooked &&
                        <div>
                            <div>
                                <h3><strong>Book Your Next Window Cleaning and Get Up To 20% Off</strong></h3>
                            </div>
                            <div className="my-3">
                                Our schedule fills up very quickly, so it's important that you set an appointment date as soon as possible. We will contact you a week before to confirm the appointment date and time.
                            </div>
                            <div className="my-3">
                                <strong>The earlier the appointment date, the cheaper the price.</strong> If you wait until it's time to clean your windows to book an appointment, then the price will be the same as this time.
                            </div>
                            <div className="p-4" style={{ borderWidth: 0.5, borderStyle: "solid" }}>
                                <div className="my-2">
                                    <h4>Get 20% 0ff</h4>
                                </div>
                                <div className="my-3">
                                    If you schedule your next window cleaning before <strong>{getMonthsFromNow(4)}</strong>
                                </div>
                            </div>
                            <div className="p-4 my-3" style={{ borderWidth: 0.5, borderStyle: "solid" }}>
                                <div className="my-2">
                                    <h4>Get 15% 0ff</h4>
                                </div>
                                <div className="my-3">
                                    If you schedule your next window cleaning before <strong>{getMonthsFromNow(7)}</strong>
                                </div>
                            </div>
                            <div className="p-4 my-3" style={{ borderWidth: 0.5, borderStyle: "solid" }}>
                                <div className="my-2">
                                    <h4>Get 5% 0ff</h4>
                                </div>
                                <div className="my-3">
                                    If you schedule your next window cleaning for anytime as long as you schedule today
                                </div>
                            </div>
                            <div className="my-4">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        views={['day']}
                                        label="Select an Appointment Date"
                                        minDate={dayjs()}
                                        value={date}
                                        onChange={(newValue) => { setDate(newValue) }}
                                        shouldDisableDate={isWeekend}
                                        renderInput={(params) => <TextField fullWidth={true} {...params} helperText={null} />} />
                                </LocalizationProvider>
                            </div>
                            {
                                date &&
                                <div className="my-3">
                                    <Error />
                                    <Button onClick={bookDate} fullWidth={true} variant="contained">Book for {date.format('MMMM DD YYYY')}</Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default SetAppointmentDate