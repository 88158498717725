import { Button } from '@mui/material'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Config } from '../config'
import useFirebase from '../hooks/useFirebase'
import { AskForConstants, EstimateStatus, Invoice, InvoiceStatus } from '../types/invoiceType'
import DisplayInvoice from './displayInvoice'
import Header from './header'
import Referrals from './referrals'
import Review from './review'
import StripePayment from './stripePayment'

/* Allows a user to make payment on an invoice. On this page we also prompt the user for reviews and for referrals, depending on which value is set in the database for what to ask the customer for. */
const Payment = () => {

    const { invoiceId } = useParams()

    const [invoice, setInvoice] = React.useState<Invoice | null>(null)

    const { db, analytics } = useFirebase()

    const stripePublishableKey = Config.Env.StripePublishableKey

    useEffect(() => {
        if (invoice?.status == "paid") {
            window.location.href = "/success/" + invoiceId
        }

        if (invoice?.isEstimate === true)  {
            window.location.href = "/estimate/" + invoiceId
        }
    }, [invoice])

    const updatePrice = (percentage: number) => {
        if (invoice) {
            const newPrice = invoice.amount * (1 - (percentage / 100))
            setInvoice({ ...invoice, amount: newPrice })
        }
    }

    const shouldShowReview = () => {
        if (invoice && invoice.isEstimate === false && invoice.askFor == AskForConstants.review && invoice.status == InvoiceStatus.open) {
            return true
        }

        return false
    }

    const acceptEstimate = () => {
        if (!invoice) { return }

        const docRef = doc(db, "invoices", invoice.id);
        updateDoc(docRef, {
            status: EstimateStatus.accepted
        }).then(() => {
            setInvoice({ ...invoice, status: EstimateStatus.accepted })
            console.log("Document successfully updated!");
        })
    }


    return (
        <div style={{ maxWidth: "800px" }} className="mx-3">
            {
                <Header />
            }
            {
                shouldShowReview() &&
                <div className="my-5">
                    <Review />
                </div>
            }
            {
                <Container className="my-4">
                    <Row>
                        <Col>
                            <h1>Defined Window Cleaning has sent you an {invoice?.isEstimate ? "Estimate" : "Invoice"}</h1>
                        </Col>
                    </Row>
                    {
                        invoice?.status === EstimateStatus.accepted &&
                        <Row>
                            <Col>
                                <h5 style={{ color: "green" }}>Estimate Accepted</h5>
                            </Col>
                        </Row>
                    }
                </Container>
            }
            {
                invoice && invoice.isEstimate === false && invoice.askFor == AskForConstants.referral &&
                <div className="my-5">
                    <Referrals referree={invoice.fullName} discountPercent={10} updatePrice={updatePrice} />
                </div>
            }
            {invoiceId &&
                <div className="my-3">
                    <DisplayInvoice invoiceId={invoiceId} setInvoice={setInvoice} />
                </div>
            }
            {
                invoice &&
                < div >
                    <StripePayment hostUrl={Config.Env.StripeUrl} publishableKey={stripePublishableKey} price={invoice.amount} isEstimate={invoice.isEstimate} />
                </div>
            }
            {
                invoice?.isEstimate === true && invoice?.status === EstimateStatus.open &&
                <Button variant="contained" color="primary" fullWidth={true} onClick={acceptEstimate} >Accept Estimate</Button>
            }

        </div >
    )
}

export default Payment