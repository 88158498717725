import React from 'react'

const useError = () => {
    const [error, setError] = React.useState<string>()

    const Error = () => {
        return (
            <div>
                {
                    error &&
                    < div className="alert alert-danger" >
                        {error}
                    </div >
                }
            </div >                   
        )
    }

    return { setError, Error }
}

export default useError