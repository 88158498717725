import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
  redirect
} from "react-router-dom";

import Payment from './components/payment';
import CreateInvoice from './components/createInvoice';
import PaymentSuccessful from './components/paymentsuccessful';
import { createTheme, CssBaseline, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { Box, ThemeProvider } from '@mui/system';
import SidebarMenu from './components/sidebarMenu';
import Estimate from './components/estimate';


function App() {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const drawerWidth = 300;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });


  return (
    <div className="d-flex justify-content-center">
      <BrowserRouter>
        <Routes>
          <Route path="/invoice/:invoiceId" element={<Payment />} />
          <Route path="/estimate/:estimateId" element={<Estimate />} />
          <Route path="/createInvoice" element={<CreateInvoice />} />
          <Route path="/createInvoice/:clientId" element={<CreateInvoice />} />
          <Route path="/success/:invoiceId/:email" element={<PaymentSuccessful />} />
          <Route path="/success/:invoiceId" element={<PaymentSuccessful />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
