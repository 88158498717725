const Production: IConfig = {
    StripeUrl: "https://de-stripe.herokuapp.com",
    MessagesUrl: "https://defined-cleaning.herokuapp.com",
    StripePublishableKey: "pk_live_ydmLXp2GB54LeZHh3TQ4Zwt1",
    PaymentSuccessfulReturnUrl: "https://payment.definedcleaning.com"
}

const Debug:IConfig = {
    StripeUrl: "http://localhost:5000",
    MessagesUrl: "http://localhost:4000",
    StripePublishableKey: "pk_test_tu1losGXCQrudFqfygxKxSYn",
    PaymentSuccessfulReturnUrl: "http://localhost:3000"
}

type IConfig = {
    StripeUrl: string,
    MessagesUrl: string,
    StripePublishableKey: string,
    PaymentSuccessfulReturnUrl: string
}

export const Config = {
    Env: Production
}
