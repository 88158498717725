import { Button, TextField } from '@mui/material'
import { blue } from '@mui/material/colors'
import { logEvent } from 'firebase/analytics'
import { addDoc, collection } from 'firebase/firestore'
import React, { useState } from 'react'
import { Col, Container, Row, Form } from 'react-bootstrap'
import useFirebase from '../hooks/useFirebase'
import NetworkService from '../services/networkService'
import validator from 'validator'

const Referrals = (props: {
    referree: string,
    updatePrice: any,
    discountPercent: number
}) => {

    const [phoneNumber, setPhoneNumber] = useState<string>("")

    const [name, setName] = useState<string>("")

    const { db, analytics } = useFirebase()

    const [error, setError] = useState<string>("")

    const [processing, setProcessing] = useState<boolean>(false)

    const [successMessage, setSuccessMessage] = useState<string>("")

    const sendMessageToReferral = async (event: any) => {
        if (validator.isMobilePhone(phoneNumber, 'en-US') === false) {
            setError("Please enter a valid phone number.")
            return
        }

        event.preventDefault()
        setError("")

        setProcessing(true)

        let networkService = new NetworkService()

        try {
            const data = await networkService.sendReferralMessageToPhoneNumber(phoneNumber, props.referree, name)
            setProcessing(false)
            setSuccessMessage("Message sent successfully! Thanks so much for your referral!")
            if (data) {
                console.log(data)

                const docRef = await addDoc(collection(db, "referrals"), {
                    phoneNumber: phoneNumber,
                    name: name
                })

                props.updatePrice(props.discountPercent)

                logEvent(analytics, 'send_message_to_referral', {
                    phoneNumber: phoneNumber,
                    name: name,
                    docRef: docRef.id
                })
            }
        } catch (error) {
            setProcessing(false)
            console.log(error)
            setError("Looks like we had a problem sending the message. Please make sure the number is correct and try again.")
        }
    }

    return (
        <Form onSubmit={sendMessageToReferral}>
            <Container style={{ backgroundColor: blue[50] }} className="p-4">
                {
                    successMessage &&
                    <Row>
                        <Col>
                            <strong>{successMessage}</strong>
                        </Col>
                    </Row>
                }
                {
                    !successMessage &&
                    <div>
                        <Row>
                            <Col>
                                <strong>Thank you so much for working with us!</strong> We would greatly appreciate it if you could refer us to your friends and family.  Referrals or the lifeblood of our company. If you do provide us with a referral, we will give you a <strong>10% discount on your next invoice!</strong>. All you have to do is enter the name and phone number of the person you would like to refer below. It would really help us out!
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                <TextField fullWidth={true} id="textfield-name-referral" label="Full Name of Referral" variant="outlined" color="secondary" onChange={(event) => setName(event.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField fullWidth={true} id="textfield-phonenumber-referral" label="Phone Number of Referral" variant="outlined" color="secondary" type="tel" required onChange={(event) => setPhoneNumber(event.target.value)} />
                            </Col>
                        </Row>
                        {
                            error &&
                            <Row className="my-3">
                                <Col>
                                    <p style={{ color: "red" }}>{error}</p>
                                </Col>
                            </Row>
                        }
                        <Row className="my-3">
                            <Col>
                                {
                                    processing ? <Button variant="contained" type="submit" disabled>Processing...</Button> : <Button variant="contained" type="submit">Submit Referral</Button>

                                }
                            </Col>
                        </Row>
                    </div>
                }
            </Container>


        </Form>
    )
}

export default Referrals