import { useState, useEffect } from 'react'

const usePayment = (url:string, amount: number) => {
    const [paymentIntent, setPaymentIntent] = useState<{
        clientSecret: string,
        price: number
    }>()

    const fetchPaymentIntent = async () => {
        const response = await fetch(`${url}/paymentIntent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                price: amount
            })
        })

        const data = await response.json()
        setPaymentIntent(data)
    }

    useEffect(() => {
        
        fetchPaymentIntent()
    }, [])

    return { paymentIntent, fetchPaymentIntent }
}

export default usePayment