import { Button, FormControlLabel, FormControl, Radio, RadioGroup, TextField, Alert } from '@mui/material'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { addDoc, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, FormLabel, Row } from 'react-bootstrap'
import { firebaseConfig } from '../firebaseConfig'
import { AskForConstants, Invoice, PayingClient } from '../types/invoiceType'
import { v4 as uuidv4 } from 'uuid';
import useError from '../hooks/useError'
import { useParams } from 'react-router-dom'

const CreateInvoice = () => {

    // The customer's full name
    const [fullName, setFullName] = React.useState<string>("")

    //  The customer's address
    const [address, setAddress] = React.useState<string>("")

    // The title of the invoice 
    const [title, setTitle] = useState<string>("")

    // The message to show the customer when they view the invoice
    const [message, setMessage] = useState<string>("")

    // The amount of the invoice
    const [amount, setAmount] = useState<number>(0)

    // The services that the customer is paying for
    const [servicesDone, setServicesDone] = useState<string>("")

    // The link to view the invoice
    const [invoiceLink, setInvoiceLink] = useState<string>("")

    const [lineItems, setLineItems] = useState<any[]>([])

    // The firebase app instance
    const app = initializeApp(firebaseConfig);

    // The firebase firestore instance
    const db = getFirestore(app)

    // The firebase analytics instance
    const analytics = getAnalytics(app);

    const [processing, setProcessing] = useState<boolean>(false)

    const [askFor, setAskFor] = useState<string>(AskForConstants.referral)

    const { setError, Error } = useError()

    const { clientId } = useParams()

    const [isEstimate, setIsEstimate] = useState<boolean>(false)

    const [costNotEditable, setCostNotEditable] = useState<boolean>(false)

    useEffect(() => {
        const getClient = async () => {
            if (!clientId) { return }

            setIsEstimate(true)
            const docRef = doc(db, "clients", clientId);
            const clientSnap = await getDoc(docRef);
            const client = clientSnap.data() as PayingClient

            setFullName(client.firstName)
            setAddress(client.address)
            setAmount(client.costOfService)
            setLineItems(client.lineItems ?? [])
            setTitle("Solar Screens")
            setMessage(client.notes ?? "")
            setCostNotEditable(true)
        }

        getClient()
    }, [])

    /**
     * Creates a new invoice in the database and shows a link to the invoice to send to the user
     */
    const createInvoice = async (event: any) => {
        event.preventDefault()

        let customerId = clientId ?? uuidv4()

        if (!clientId) {
            // Create the customer            
            let client: PayingClient = {
                firstName: fullName,
                address: address,
                zipCode: "00000",
                dateOfService: new Date(),
                costOfService: amount,
                lineItems: lineItems.length > 0 ? lineItems : [servicesDone],
                servicesProvided: [servicesDone],
                id: customerId
            }

            try {
                await setDoc(doc(db, "clients", customerId), client)
            } catch (error) {
                console.log(error)
                logEvent(analytics, 'error', {
                    client: client,
                    error: error
                })

                setError("There wasn an error creating the invoice. Please try again later.")
                return
            }
        }

        let invoice: Invoice = {
            fullName: fullName,
            address: address,
            title: title,
            message: message,
            amount: amount,
            servicesDone: [servicesDone],
            lineItems: lineItems,
            status: "open",
            askFor: askFor,
            clientId: customerId,
            invoiceLink: "",
            id: uuidv4(),
            isEstimate: isEstimate,
            dateCreated: new Date()
        }

        try {
            await setDoc(doc(db, "invoices", invoice.id), invoice)
            let docRef = doc(db, "invoices", invoice.id)
            let invoiceLink = `https://payment.definedcleaning.com/${isEstimate == true ? "estimate" : "invoice"}/${docRef.id}`

            updateDoc(docRef, {
                invoiceLink: invoiceLink
            })

            setInvoiceLink(invoiceLink)

            logEvent(analytics, 'invoice_created', {
                docRefId: docRef.id
            })
        } catch (error) {
            console.log(error)
            logEvent(analytics, 'error', {
                invoice: invoice,
                message: error
            })

            setError("There wasn an error creating the invoice. Please try again later.")
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAskFor((event.target as HTMLInputElement).value);
    };

    return (
        <Form onSubmit={createInvoice}>
            <Container className="mt-4">
                {/* Create Invoice */}
                <Row>
                    <Col className="mb-3">
                        <h3>Create {isEstimate === true ? "Estimate" : "Invoice"}</h3>
                    </Col>
                </Row>
                <hr></hr>
                {/* Customer Information */}
                <Row>
                    <Col className="mb-3">
                        <strong>Customer Information</strong>
                    </Col>
                </Row>
                {/* Full Name */}
                <Row>
                    <Col xs="12" className="my-3">
                        <TextField fullWidth={true} id="textfield-fullname" value={fullName} label="Full Name" variant="outlined" color="secondary" onChange={(event) => setFullName(event.target.value)} />
                    </Col>
                </Row>
                {/* Address */}
                <Row>
                    <Col xs="12">
                        <TextField fullWidth={true} id="textfield-address" value={address} label="Address" variant="outlined" color="secondary" onChange={(event) => setAddress(event.target.value)} />
                    </Col>
                </Row>
                {/* Invoice Information */}
                <Row>
                    <Col className="my-4">
                        <strong>{isEstimate === true ? "Estimate" : "Invoice"} Information</strong>
                    </Col>
                </Row>
                {/* Title */}
                <Row>
                    <Col xs="12" className="mb-3">
                        <TextField fullWidth={true} id="textfield-invoicetitle" value={title} label="Invoice Title" variant="outlined" color="secondary" onChange={(event) => setTitle(event.target.value)} />
                    </Col>
                </Row>
                {/* Message */}
                <Row>
                    <Col xs="12" className="mb-3">
                        <TextField multiline={true} minRows={5} fullWidth={true} value={message} id="textfield-message" label="Message" variant="outlined" color="secondary" onChange={(event) => setMessage(event.target.value)} />
                    </Col>
                </Row>

                {/* Amount */}
                {
                    costNotEditable &&
                    <Row>
                        <Col xs="12" className="mb-2">
                            <Alert variant='outlined' severity='warning'>You cannot edit the cost without approval from the owner of the company. If for whatever reason this price needs to be updated, text or call (702) 747-0901.</Alert>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs="12" className="mb-3">
                        <TextField fullWidth={true} id="textfield-amount" type="number" value={amount} label="Amount" variant="outlined" color="secondary" inputProps={{ readOnly: costNotEditable }} onChange={(event) => setAmount(parseInt(event.target.value))} />
                    </Col>
                </Row>

                {/* Service Done */}
                {
                    lineItems.length == 0 &&
                    <Row>
                        <Col xs="12" className="mb-3">
                            <TextField fullWidth={true} id="textfield-servicesdone" label="Services Done" variant="outlined" color="secondary" onChange={(event) => setServicesDone(event.target.value)} />
                        </Col>
                    </Row>
                }
                {
                    <div className="p-3 mb-4" style={{ borderWidth: 0.5, borderStyle: "solid", borderColor: "black" }}>
                        <Row>
                            <Col xs="12">
                                <strong>Line Items</strong>
                            </Col>
                        </Row>
                        {
                            lineItems.length > 0 && lineItems.map((item, index) => {
                                return (
                                    <Row>
                                        <Col xs="12">
                                            <hr></hr>
                                        </Col>
                                        <Col xs="8" className="mb-1">
                                            {index + 1}. <strong>{item.name}</strong>
                                        </Col>
                                        <Col xs="4" className="d-flex justify-content-end">
                                            <strong>${item.unitPrice}</strong>
                                        </Col>
                                        <Col xs="12">
                                            <p style={{ fontSize: "12px" }}>{item.description}</p>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="8" className="mb-3">
                                Total Price:
                            </Col>
                            <Col xs="4" className="mb-3 d-flex justify-content-end">
                                <strong>${amount}</strong>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    isEstimate == false &&
                    <Row>
                        <Col xs="12" className="mb-3">
                            <FormControl>
                                <FormLabel id="radio-buttons-group-label">Ask the customer for...</FormLabel>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={handleChange}
                                    value={askFor}
                                >
                                    <FormControlLabel value={AskForConstants.referral} control={<Radio />} label={AskForConstants.referral.toUpperCase()} />
                                    <FormControlLabel value={AskForConstants.review} control={<Radio />} label={AskForConstants.review.toUpperCase()} />
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs="12" className="my-3">
                        <div className="alert alert-warning">
                            {
                                !invoiceLink ? <div>The link to the invoice will be shown here after you create the invoice</div> : <div><a href={invoiceLink}>{invoiceLink}</a></div>
                            }
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col xs="12" className="my-3">
                        {
                            processing ? <Button variant="contained" color="secondary" disabled={true}>Processing...</Button> : <Button variant="contained" color="secondary" type="submit">Create {isEstimate === true ? "Estimate" : "Invoice"}</Button>
                        }
                    </Col>
                </Row>
            </Container >
        </Form >
    )
}

export default CreateInvoice
