export interface Invoice {
  fullName: string;
  address: string;
  title: string;
  message: string;
  amount: number;
  servicesDone: string[];
  invoiceLink: string;
  status: string;
  askFor?: string;
  lineItems?: any;
  clientId: string;
  id: string;
  isEstimate: boolean;
  depositLink?: string;
  depositAmount?: number;
  dateCreated: any;
}

export const AskForConstants = {
  referral: "referral",
  review: "review"
};

export const InvoiceStatus = {
  paid: "paid",
  open: "open"
};

export const EstimateStatus = {
  accepted: "accepted",
  open: "open"
};

export type PayingClient = {
  firstName: string;
  lastName?: string;
  address: string;
  phoneNumber?: string;
  email?: string;
  zipCode: string;
  // Firebase saves date objects as a FirebaseTimestamp which Typescript is unaware of, so we need to handle the date as object type any
  dateOfService: any;
  costOfService: number;
  servicesProvided: string[];
  lineItems?: any[];
  notes?: string;
  id: string;
  howCustomerFoundUs?: string;
};
