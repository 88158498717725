import React from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'

/**
 * It's a function that takes in a header prop and returns a form with a text area and a submit
 * button
 * @param props - { header: String }
 * @returns A form with a text area and a submit button.
 */
const FeedbackTextArea = (props: { header: string, submitFeedback: (feedback: string) => void }) => {

    const [feedback, setFeedback] = React.useState<string>("")

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        props.submitFeedback(feedback)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Col>
                    {props.header}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm="12">
                    <Form.Group controlId="formFeedback">
                        <Form.Control value={feedback} as="textarea" onChange={(event) => {
                            event.preventDefault()
                            setFeedback(event.target.value)
                        }} placeholder="" rows={3} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary" type="submit">Submit Feedback</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default FeedbackTextArea