import React from 'react'
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

const AddToCalendar = (props: { date: string, price: number }) => {

    React.useEffect(() => {
        atcb_init();
        console.log(props.date)
    }, [])

    return (
        <div>

            <div className="atcb">
                {'{'}
                "name":"Window Cleaning from Defined Window Cleaning and Screens",
                "description":"Defined Window Cleaning and Screens will be coming to clean our windows. They will call you to confirm the appointment or you can call as well. The cost will be ${props.price}",
                "startDate": "{props.date}",
                "endDate": "{props.date}",
                "location":"My House",
                "label":"Add to Calendar",
                "options":[
                "Apple",
                "Google",
                "iCal",
                "Microsoft365",
                "Outlook.com",
                "Yahoo"
                ],
                "timeZone":"Europe/Berlin",
                "iCalFileName":"Reminder-Event"
                {'}'}
            </div>

        </div>
    )
}

export default AddToCalendar