import { TextField } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import usePayment from '../hooks/usePayment';
import CheckoutForm from './checkoutForm';

const StripePayment = (props: {
    publishableKey: string,
    hostUrl: string,
    price: number,
    isEstimate?: boolean
}) => {

    const stripePromise = loadStripe(props.publishableKey);

    const { paymentIntent, fetchPaymentIntent } = usePayment(props.hostUrl, props.price)

    const [options, setOptions] = useState<any>()

    const [price, setPrice] = useState<number>(props.price)

    const [email, setEmail] = useState<string>("")

    useEffect(() => {
        if (paymentIntent) {
            const options = {
                clientSecret: paymentIntent.clientSecret
            }

            setPrice(paymentIntent.price / 100)
            setOptions(options)
        }
    }, [paymentIntent])

    return (
        <div>
            {
                options && (props.isEstimate === undefined || props.isEstimate === false) &&
                <Container>
                    <Row className="mb-4">
                        <Col>
                            <TextField fullWidth={true} id="textfield-email" label="Email to Send Receipt" variant="outlined" color="secondary" onChange={(event) => setEmail(event.target.value)} />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col>
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm email={ email } />
                            </Elements>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    )
}

export default StripePayment