import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'
import DisplayInvoice from './displayInvoice'
import Header from './header'
import { Invoice, InvoiceStatus } from '../types/invoiceType'
import useFirebase from '../hooks/useFirebase'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import { logEvent } from 'firebase/analytics'
import NetworkService from '../services/networkService'
import SetAppointmentDate from './setAppointmentDate'
import { purple } from '@mui/material/colors'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentSuccessful = () => {

    const { invoiceId, email } = useParams()

    const query = useQuery();

    const [invoice, setInvoice] = React.useState<Invoice | null>(null)

    const { db, analytics } = useFirebase()

    useEffect(() => {
        // Check to see if there is a payment_intent value, and if there is then we set the status of this invoice to paid

        if (!invoiceId) {
            return
        }

        let paymentIntent = query.get("payment_intent")

        if (paymentIntent && email) {
            const invoiceRef = doc(db, "invoices", invoiceId)

            // Add the email to the payment intent's receipt_email field so that way the email is sent automatically when the payment is successful
            let networkService = new NetworkService()
            networkService.addReceiptEmailToPaymentIntent(paymentIntent, email)

            updateDoc(invoiceRef, {
                status: InvoiceStatus.paid,
                paymentIntent: paymentIntent,
                email: email
            });

            // Add the email to our email list
            addDoc(collection(db, "email-list"), {
                email: email
            })

            // Log that the invoice was paid
            logEvent(analytics, "invoice_paid", {
                invoiceId: invoiceId,
                paymentIntent: paymentIntent,
                email: email
            })
        }



    }, [])

    return (
        <div>
            <Header />
            <Container className="my-5">
                <div className="p-4" style={{ borderWidth: 0.5, borderStyle: "solid" }}>
                    <Row>
                        <Col xs={12}>
                            <h3>Payment Successful!</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-3" xs={12}>
                            <div>
                                Thank you so much for your prompt payment! We appreciate your business, and we look forward to working with you again!
                            </div>
                        </Col>
                    </Row>

                </div>

            </Container>
            <Container>
                <Row>
                    <Col>
                        {
                            invoice && <SetAppointmentDate invoice={invoice} />
                        }
                    </Col>
                </Row>
            </Container>
            {
                invoiceId &&
                <div className="mx-3">
                    <DisplayInvoice invoiceId={invoiceId} setInvoice={setInvoice} />
                </div>
            }
        </div>
    )
}

export default PaymentSuccessful