import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import React, { useState } from 'react'
import { firebaseConfig } from '../firebaseConfig';

const useFirebase = () => {
        // The firebase app instance
        const app = initializeApp(firebaseConfig)

        // The firebase firestore instance
        const db = getFirestore(app)
    
        // The firebase analytics instance
        const analytics = getAnalytics(app);

        return { db, analytics }
}

export default useFirebase