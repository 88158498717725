import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Config } from "../config";
import { firebaseConfig } from "../firebaseConfig";

class NetworkService {
  // The firebase app instance
  app = initializeApp(firebaseConfig);

  // The firebase firestore instance
  db = getFirestore(this.app);

  // The firebase analytics instance
  analytics = getAnalytics(this.app);

  constructor() {}

/**
 * It takes a payment intent ID and an email address, and adds the email address to the payment intent
 * @param {string} paymentIntentId - The ID of the payment intent that you want to add the receipt
 * email to.
 * @param {string} email - The email address to send the receipt to.
 * @returns The payment intent object.
 */
  addReceiptEmailToPaymentIntent(paymentIntentId: string, email: string) {
    return new Promise((resolve, reject) => {
      // Add receipt email to payment intent
      fetch(`${Config.Env.StripeUrl}/addReceiptEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId,
          email: email,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          logEvent(this.analytics, "receipt_email_added", {
            paymentIntentId: paymentIntentId,
            email: email,
          });
          resolve(data)
        })
        .catch((error) => {
          console.error("Error:", error);
          logEvent(this.analytics, "receipt_email_error", {
            paymentIntentId: paymentIntentId,
            email: email,
            error: error,
          });
          reject(error)
        });
    });
  }

  /**
   * It sends a referral message to a phone number
   * @param {string} phoneNumber - The phone number to send the message to.
   * @param {string} fromName - The name of the person who referred the new customer
   * @param {string} toName - The name of the person you're sending the message to.
   * @returns A promise that resolves to the data returned from the API.
   */
  sendReferralMessageToPhoneNumber(
    phoneNumber: string,
    fromName: string,
    toName: string
  ) {
    let message = `
Hi ${toName}!  
    
${fromName} has just referred you to Defined Window Cleaning and Solar Screens. Please call or text us at 702-747-0901 to get your free estimate.  Schedule a service within the next week and you'll get 15% off!  Hope to speak to you soon!`;

    return new Promise((resolve, reject) => {
      // Send referral message to phone number
      fetch(`${Config.Env.MessagesUrl}/sendCustomMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          message: message,
          fromNumber: "+17028205488", // This is completely irrelevant, but it's required by the API, so I'm just using a random number, but it's not used. Needs to be fixed.
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          logEvent(this.analytics, "referral_message_sent", {
            phoneNumber: phoneNumber,
            message: message,
          });

          resolve(data);
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
          logEvent(this.analytics, "referral_message_error", {
            phoneNumber: phoneNumber,
            message: message,
            error: error,
          });

          reject(error);
        });
    });
  }
}

export default NetworkService;
